<template>
  <div class="container">
    <div class="reboundary-card mt-5">
      <h1>
        Feedback
      </h1>
      <p>
        Please let us know what you think of the app, and how we can improve it.
      </p>
      <p>To get in contact, email Tony Irish at tonyi@sedadvisory.com with your comments.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Feedback',

}
</script>
<style scoped>

.underline-input {
  margin-bottom: 0;
  margin-top: 0;
}

</style>
